@import '../sass/variables';

.modal-content {

    .modal-header {
        background: $error-background;
        padding: 25px;

        h2 {
            margin: 0;
            padding: 0;
            font-family: 'Comfortaa', cursive;
            color: #eee;
        }
    }

    .modal-body {
        padding: 25px;
        p {
            font-family: 'Raleway', sans-serif;
            padding: 0;
            font-size: 1rem;
            line-height: 1.5;

        }

        p.center {
            text-align: center;
        }
    }
}
