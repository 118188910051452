@import '../sass/variables';

.button {
    background: $info-background;
    border: none;
    color: #eee;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1rem;
    padding: 15px 32px;
    text-align: center;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

button.small {
    font-size: 0.75rem;
    padding: 10px 16px;
}

button.large {
    font-size: 1.25rem;
    padding: 20px 40px;
}

button.error {
    background: $error-background;
}

button.success {
    background: $success-background;
}

button.warning {
    background: $warning-background;
}
