@import "../../sass/variables";

.font-red {
    color: red;
}

.container {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: minmax(35px, 5vh) 90vh minmax(35px, 5vh);

    header {
        grid: 1;
        background: $theme-dark-5;
        padding: 10px;
        padding-left: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-family: 'Comfortaa', cursive;
            font-weight: 700;
            color: $theme-dark-1;
            margin: 0;
            padding: 0;
            font-size: 1.25rem;
        }

        a {
            color: $theme-dark-2;
            text-decoration: none;
            padding-right: 25px;

            &:hover {
                color: $theme-dark-3;
            }
        }
    }

    main {
        grid: 2;
        background: white;
        padding: 25px;
        min-height: 90vh;

        p {
            font-family: 'Raleway', sans-serif;
            padding: 0;
            font-size: 1rem;
            line-height: 1.5;
        }

        h2 {
            font-family: 'Comfortaa', cursive;
            font-weight: 700;
            color: $theme-dark-5;
            margin: 0;
            padding: 0;
        }

    }
    main.welcome-2 {
        background: #FFBC67;
    }

    main.welcome-3 {
        background: #455C7B;
        color: white;

        h2 {
            color: $theme-dark-1;
        }
    }

    main.welcome-4 {
        background: #DA727E;
        color: white;
    }

    footer {
        grid: 3;
        background: $theme-dark-4;
        color: $theme-dark-1;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-family: 'Raleway', sans-serif;
            margin: 0;
            padding: 10px;
            font-size: 0.75rem;
        }
    }
}
