@import '../../sass/variables';

.card {
    background: white;
    min-height: 100vh;
    max-width: 100vw;
    margin: 0;
    padding: 25px;

    h2 {
        padding: 0;
        margin: 0;
        font-family: 'Comfortaa', cursive;
        font-weight: 700;
        font-size: 1.75rem;
        max-width: 90vw;
    }

    p {
        font-family: 'Raleway', sans-serif;
        font-size: 1.3rem;
        line-height: 1.5;
        max-width: 90vw;
        margin-bottom: 25px;
    }

    p.short-description {
        font-style: italic;
        margin-bottom: 50px;
    }

    div.cta {
        text-align: center;
        margin-bottom: 50px;
        max-width: 90vw;

        a {
            text-decoration: none;
            font-family: 'Comfortaa', cursive;
            font-weight: 700;
            font-size: 1.25rem;
            background: $card-theme-1-5;
            color: white;
            padding: 15px 25px;

            svg {
                margin-left: 5px;
            }
        }
    }

}

.card-quote {
    div.quote {
        margin-top: 50px;
        min-height: 25vh;
        max-height: 75vh;
        max-width: 90vw;
        background: rgba(0, 0, 0, 0.5);

        .quote-body {
            padding: 25px;
            font-family: 'Raleway', sans-serif;
            font-size: 2.25rem;
            line-height: 1.5;
            color: white;
        }

        .quote-attribution {
            padding: 25px;
            font-family: 'Raleway', sans-serif;
            font-size: 2.25rem;
            line-height: 1.5;
            color: white;
            max-width: 90vw;
            text-align: right;
        }
    }
}
.card-theme-1 {
    background: $card-theme-1-1;

    div.cta {
        a {
            background: $card-theme-1-2;
            border: 1px solid $card-theme-1-5;
            color: $theme-dark-1;
        }
    }
}

.card-theme-2 {
    background: $card-theme-1-2;

    div.cta {
        a {
            background: $card-theme-1-1;
            border: 1px solid $card-theme-1-5;
        }
    }
}
