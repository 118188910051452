@import '../sass/variables';

.card-container {
    min-height: 100vh;
    background: $theme-dark-4;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: minmax(35, 5vh) minmax(95vh, auto);

    header {
        grid: 1;
        background: $theme-dark-5;
        padding: 10px;
        padding-left: 25px;
        display: flex;
        width: 95vw;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-family: 'Comfortaa', cursive;
            font-weight: 700;
            color: $theme-dark-1;
            margin: 0;
            padding: 0;
            font-size: 1.25rem;
        }

        a {
            color: $theme-dark-2;
            text-decoration: none;
            padding-right: 25px;

            &:hover {
                color: $theme-dark-3;
            }
        }
    }

    .card-list {
        grid: 2;
    }
}


