@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Raleway);
/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
body {
  margin: 0;
  padding: 0; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.InitialScreen {
  height: 100vh;
  width: 100vw;
  background: #404040;
  display: flex;
  align-items: center;
  justify-content: center; }
  .InitialScreen h1 {
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    color: #D9D9D9;
    margin: 0;
    padding: 0;
    max-width: 50%;
    font-size: 2.75rem; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.font-red {
  color: red; }

.container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: minmax(35px, 5vh) 90vh minmax(35px, 5vh); }
  .container header {
    grid: 1;
    background: #262626;
    padding: 10px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .container header h1 {
      font-family: 'Comfortaa', cursive;
      font-weight: 700;
      color: #D9D9D9;
      margin: 0;
      padding: 0;
      font-size: 1.25rem; }
    .container header a {
      color: #A6A6A6;
      text-decoration: none;
      padding-right: 25px; }
      .container header a:hover {
        color: #737373; }
  .container main {
    grid: 2;
    background: white;
    padding: 25px;
    min-height: 90vh; }
    .container main p {
      font-family: 'Raleway', sans-serif;
      padding: 0;
      font-size: 1rem;
      line-height: 1.5; }
    .container main h2 {
      font-family: 'Comfortaa', cursive;
      font-weight: 700;
      color: #262626;
      margin: 0;
      padding: 0; }
  .container main.welcome-2 {
    background: #FFBC67; }
  .container main.welcome-3 {
    background: #455C7B;
    color: white; }
    .container main.welcome-3 h2 {
      color: #D9D9D9; }
  .container main.welcome-4 {
    background: #DA727E;
    color: white; }
  .container footer {
    grid: 3;
    background: #404040;
    color: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center; }
    .container footer p {
      font-family: 'Raleway', sans-serif;
      margin: 0;
      padding: 10px;
      font-size: 0.75rem; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.category-button {
  width: 15vh;
  height: 15vh;
  background: #eee;
  color: black;
  margin-right: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  border: 1px solid #eee; }
  .category-button span {
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1rem; }

.category-button.selected {
  background: #404040;
  color: #D9D9D9;
  border: 1px solid #D9D9D9; }

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px; }
  .actions button.link {
    color: black;
    margin-bottom: 10px;
    background: none;
    border: none;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.button {
  background: #0079C1;
  border: none;
  color: #eee;
  font-family: 'Comfortaa', cursive;
  font-weight: 700;
  font-size: 1rem;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none; }
  .button:hover {
    cursor: pointer; }

button.small {
  font-size: 0.75rem;
  padding: 10px 16px; }

button.large {
  font-size: 1.25rem;
  padding: 20px 40px; }

button.error {
  background: #C6151B; }

button.success {
  background: #7CBA42; }

button.warning {
  background: #FF9900; }

.category-select-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 10px; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.card-container {
  min-height: 100vh;
  background: #404040;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: minmax(35, 5vh) minmax(95vh, auto); }
  .card-container header {
    grid: 1;
    background: #262626;
    padding: 10px;
    padding-left: 25px;
    display: flex;
    width: 95vw;
    align-items: center;
    justify-content: space-between; }
    .card-container header h1 {
      font-family: 'Comfortaa', cursive;
      font-weight: 700;
      color: #D9D9D9;
      margin: 0;
      padding: 0;
      font-size: 1.25rem; }
    .card-container header a {
      color: #A6A6A6;
      text-decoration: none;
      padding-right: 25px; }
      .card-container header a:hover {
        color: #737373; }
  .card-container .card-list {
    grid: 2; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.card {
  background: white;
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  padding: 25px; }
  .card h2 {
    padding: 0;
    margin: 0;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1.75rem;
    max-width: 90vw; }
  .card p {
    font-family: 'Raleway', sans-serif;
    font-size: 1.3rem;
    line-height: 1.5;
    max-width: 90vw;
    margin-bottom: 25px; }
  .card p.short-description {
    font-style: italic;
    margin-bottom: 50px; }
  .card div.cta {
    text-align: center;
    margin-bottom: 50px;
    max-width: 90vw; }
    .card div.cta a {
      text-decoration: none;
      font-family: 'Comfortaa', cursive;
      font-weight: 700;
      font-size: 1.25rem;
      background: #455C7B;
      color: white;
      padding: 15px 25px; }
      .card div.cta a svg {
        margin-left: 5px; }

.card-quote div.quote {
  margin-top: 50px;
  min-height: 25vh;
  max-height: 75vh;
  max-width: 90vw;
  background: rgba(0, 0, 0, 0.5); }
  .card-quote div.quote .quote-body {
    padding: 25px;
    font-family: 'Raleway', sans-serif;
    font-size: 2.25rem;
    line-height: 1.5;
    color: white; }
  .card-quote div.quote .quote-attribution {
    padding: 25px;
    font-family: 'Raleway', sans-serif;
    font-size: 2.25rem;
    line-height: 1.5;
    color: white;
    max-width: 90vw;
    text-align: right; }

.card-theme-1 {
  background: #FFBC67; }
  .card-theme-1 div.cta a {
    background: #DA727E;
    border: 1px solid #455C7B;
    color: #D9D9D9; }

.card-theme-2 {
  background: #DA727E; }
  .card-theme-2 div.cta a {
    background: #FFBC67;
    border: 1px solid #455C7B; }

/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
/* Button Colors */
/* Card Themes */
/* RGB: 150 206 180 HSB: 152 27 81 */
/* RGB: 255 238 173 HSB: 48 32 100 */
/* RGB: 255 111 105 HSB: 2 59 100 */
/* RGB: 255 204 92 HSB: 41 64 100 */
/* RGB: 170 216 175 HSB: 128 21 85 */
.modal-content .modal-header {
  background: #C6151B;
  padding: 25px; }
  .modal-content .modal-header h2 {
    margin: 0;
    padding: 0;
    font-family: 'Comfortaa', cursive;
    color: #eee; }

.modal-content .modal-body {
  padding: 25px; }
  .modal-content .modal-body p {
    font-family: 'Raleway', sans-serif;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5; }
  .modal-content .modal-body p.center {
    text-align: center; }

