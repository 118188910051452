
/* Dark Color Scheme

Hex: #262626;
HSB: 0, 0, 15;

Hex: #404040;
HSB: 0, 0, 25;

Hex: #737373;
HSB: 0, 0, 45;

Hex: #A6A6A6;
HSB: 0, 0, 65;

Hex: #D9D9D9;
HSB: 0, 0, 85;
*/
$theme-dark-5: #262626;
$theme-dark-4: #404040;
$theme-dark-3: #737373;
$theme-dark-2: #A6A6A6;
$theme-dark-1: #D9D9D9;


/* Button Colors */
$error-background: #C6151B;

$info-background: #0079C1;

$success-background: #7CBA42;

$warning-background: #FF9900;

/* Card Themes */

// Default
$card-theme-1-1: #FFBC67;
$card-theme-1-2: #DA727E;
$card-theme-1-3: #AC6C82;
$card-theme-1-4: #685C79;
$card-theme-1-5: #455C7B;

// Beach

/* RGB: 150 206 180 HSB: 152 27 81 */
$card-theme-beach-1: #96CEB4;

/* RGB: 255 238 173 HSB: 48 32 100 */
$card-theme-beach-2: #FEEEAD;

/* RGB: 255 111 105 HSB: 2 59 100 */
$card-theme-beach-3: #FF6F69;

/* RGB: 255 204 92 HSB: 41 64 100 */
$card-theme-beach-4: #FFCC5C;

/* RGB: 170 216 175 HSB: 128 21 85 */
$card-theme-beach-4: #AAD880;

$card-theme-beach-4: #FFCC5C;
