@import '../sass/variables';

.category-button {
    width: 15vh;
    height: 15vh;
    background: #eee;
    color: black;
    margin-right: 10px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    border: 1px solid #eee;

    span {
        font-family: 'Comfortaa', cursive;
        font-weight: 700;
        font-size: 1rem;
    }
}

.category-button.selected {
    background: $theme-dark-4;
    color: $theme-dark-1;
    border: 1px solid $theme-dark-1;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;

    button.link {
        color: black;
        margin-bottom: 10px;
        background: none;
        border: none;
        font-family: 'Comfortaa', cursive;
        font-weight: 700;
        font-size: 1rem;
        text-decoration: underline;
        cursor: pointer;
    }
}
