@import "../../sass/variables";

.InitialScreen {
    height: 100vh;
    width: 100vw;
    background: $theme-dark-4;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-family: 'Comfortaa', cursive;
        font-weight: 700;
        color: $theme-dark-1;
        margin: 0;
        padding: 0;
        max-width: 50%;
        font-size: 2.75rem;
    }
}
